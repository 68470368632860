// グローバル変数をこのファイル内では使用せず、
// siteInfo.jsにまとめることで可読性を上げる
import * as site_info from '../common/functions/siteInfo';
import { initBidManager, initApstag, fetchHeaderBids, getHBTimeoutObject } from '../common/functions/headerBidManager';
import { makeGeoEdgeTag } from  '../common/functions/geoEdge';
import { getUserSegmentIdsLocal } from '../common/functions/piano';

// get googletag
var googletag = site_info.getGoogletag();

// GPTのスクリプトを動的にページのheadセクションに追加
site_info.makeGptTag();

//タイムアウト値のABテスト用オブジェクト
var timeout_abtest_obj = getHBTimeoutObject();
var timeOutObj = {
    failSafe: timeout_abtest_obj['failSafeTimeout'],
    apstagBid: 2000,
};

// GeoEdgeタグ挿入
makeGeoEdgeTag();

// Amazon A9, Prebid.js load library
var os = site_info.getOS();
var browser = site_info.getBrowser();
var dm_query_params = `?os=${os}&browser=${browser}&key1=${timeout_abtest_obj['prebid_wrapper_key']}`;

initBidManager( googletag, undefined, dm_query_params, timeOutObj );
initApstag();

// Initizalize variable
var headerBiddingSlots = [];
var nonHeaderBiddingSlots = [];
var apstagSlots = [];

// セグメント値取得
var goAseg = site_info.getAoneSegment();
// ページ種別（＋一記事の場合は記事ID、その他の場合はページパス）取得
var Pg_Type = 'top';
var Pg_Path = document.location.pathname;
var [asa12_mode_v,asadigi_member_type] = site_info.getAsahiMemberType();
// ChromeのサードパーティCookie廃止テスト期間中のラベル取得
var cookieDeprecationLabel = await site_info.getCookieDeprecationLabel();
// piano用のセグメント取得関数
var segmentIds = getUserSegmentIdsLocal();

//Google Ad Manager定義
if (document.location.href.indexOf('/weather/') == -1) {
    //朝デジトップ
    googletag.cmd.push(function () {
        headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/webpush', [1, 1], 'webpush').addService(googletag.pubads()));
        headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/1st_bnr', [[1, 1], [320, 50], [320, 100], [320, 180]], 'div-gpt-ad-1492779799452-0').addService(googletag.pubads()));
        headerBiddingSlots.push( googletag.defineSlot( '/57465213/www.asahi.com/sp/floating_bnr', [1, 1], 'floating_bnr' ).addService( googletag.pubads() ) );
        /*if (window.matchMedia('screen and (max-width: 335px)').matches) {
            headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/footer_rect', [[300, 250], 'fluid'], 'div-gpt-ad-1492779799452-2').addService(googletag.pubads()));
        } else {
            headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/footer_rect', [[300, 250], [336, 280], 'fluid'], 'div-gpt-ad-1492779799452-2').addService(googletag.pubads()));
        }*/
        // TOP9本目ネイティブアド誘導枠
        if ( asa12_mode_v === "0") {
            nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/sp_top_infeed_1', [[1, 1], 'fluid'], 'sp_top_infeed_1').addService(googletag.pubads()));
            nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/sp_top_infeed_2', [[1, 1], 'fluid'], 'sp_top_infeed_2').addService(googletag.pubads()));
        }

        // PR情報
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/article/sp_text_1', ['fluid'],'div-gpt-ad-sp_text_1').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/article/sp_text_2', ['fluid'],'div-gpt-ad-sp_text_2').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/article/sp_text_3', ['fluid'],'div-gpt-ad-sp_text_3').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/article/sp_text_4', ['fluid'],'div-gpt-ad-sp_text_4').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/article/sp_text_5', ['fluid'],'div-gpt-ad-sp_text_5').addService(googletag.pubads()));
        //nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/article/sp_text_6', ['fluid'],'div-gpt-ad-sp_text_6').addService(googletag.pubads()));

        if (window.matchMedia('screen and (max-width: 335px)').matches) {
            headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/1st_rect', [[300, 250], [300, 600], 'fluid'], 'div-gpt-ad-1492779799452-1').addService(googletag.pubads()));
        } else {
            headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/1st_rect', [[300, 250], [300, 600], [336, 280], 'fluid'], 'div-gpt-ad-1492779799452-1').addService(googletag.pubads()));
        }

        //＆、TJAPAN誘導枠
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/sp_top_yudo_x1', [[1, 1], 'fluid'], 'sp_top_yudo_x1').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/sp_top_yudo_tjapan', [[1, 1], 'fluid'], 'div-gpt-ad-1592469629196-0').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/sp_top_yudo_andinfeed1', [[1, 1], 'fluid'], 'div-gpt-ad-1592469534345-0').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/sp_top_yudo_andinfeed2', [[1, 1], 'fluid'], 'div-gpt-ad-1592469576044-0').addService(googletag.pubads()));

        // 注目情報
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/article/chumoku11', ['fluid'],'div-gpt-ad-sp_chumoku11').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/article/chumoku12', ['fluid'],'div-gpt-ad-sp_chumoku12').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/article/chumoku13', ['fluid'],'div-gpt-ad-sp_chumoku13').addService(googletag.pubads()));

        (goAseg) ? googletag.pubads().setTargeting("ASAHISEG", goAseg) : null;
        (asa12_mode_v) ? googletag.pubads().setTargeting("asa12_mode", asa12_mode_v) : null;
        (Pg_Type) ? googletag.pubads().setTargeting("Pg_Type", Pg_Type) : null;
        (Pg_Path) ? googletag.pubads().setTargeting("Pg_Path", Pg_Path) : null;
        (asadigi_member_type) ? googletag.pubads().setTargeting("asadigi_member_type", asadigi_member_type) : null;
        (cookieDeprecationLabel) ? googletag.pubads().setTargeting( "chrome_label" , cookieDeprecationLabel ) : null;
         // piano用k-v
        (segmentIds) ? googletag.pubads().setTargeting("CxSegments" , segmentIds) : null;
        window['sc_asa_digi_prop67'] ? googletag.pubads().setTargeting( 'ab_test_03' , 'top' ) : '';

        googletag.pubads().disableInitialLoad();
        googletag.pubads().enableSingleRequest();
        googletag.pubads().collapseEmptyDivs();
        googletag.enableServices();

        if (nonHeaderBiddingSlots.length > 0) {
            googletag.pubads().refresh(nonHeaderBiddingSlots);
        }
    });

    // A9 adSlots 定義
    apstagSlots = [{
        slotID: 'div-gpt-ad-1492779799452-0',
        slotName: '/57465213/www.asahi.com/sp/top/1st_bnr',
        sizes: [[1, 1], [320, 50], [320, 100], [320, 180]]
    }];
    if (window.matchMedia('screen and (max-width: 335px)').matches){
        apstagSlots.push({
            slotID: 'div-gpt-ad-1492779799452-1',
            slotName: '/57465213/www.asahi.com/sp/top/1st_rect',
            sizes: [[300, 600], [300, 250]]
        });
        apstagSlots.push({
            slotID: 'div-gpt-ad-1492779799452-2',
            slotName: '/57465213/www.asahi.com/sp/top/footer_rect',
            sizes: [[300, 250]]
        });
    } else {
        apstagSlots.push({
            slotID: 'div-gpt-ad-1492779799452-1',
            slotName: '/57465213/www.asahi.com/sp/top/1st_rect',
            sizes: [[300, 600], [300, 250], [336, 280]]
        });
        apstagSlots.push({
            slotID: 'div-gpt-ad-1492779799452-2',
            slotName: '/57465213/www.asahi.com/sp/top/footer_rect',
            sizes: [[300, 250], [336, 280]]
        });
    }

} else {
    //天気トップ
    googletag.cmd.push(function () {
        headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/1st_bnr', [[1, 1], [320, 50], [320, 100], [320, 180]], 'div-gpt-ad-1492771520777-0').addService(googletag.pubads()));
        nonHeaderBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/webpush', [1, 1], 'div-gpt-ad-1518060850192-0').addService(googletag.pubads()));
        if (window.matchMedia('screen and (max-width: 335px)').matches) {
            headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/footer_rect', [[300, 250], 'fluid'], 'div-gpt-ad-1492771520777-3').addService(googletag.pubads()));
        } else {
            headerBiddingSlots.push(googletag.defineSlot('/57465213/www.asahi.com/sp/top/footer_rect', [[300, 250], [336, 280], 'fluid'], 'div-gpt-ad-1492771520777-3').addService(googletag.pubads()));
        }
        (goAseg) ? googletag.pubads().setTargeting("ASAHISEG", goAseg) : null;
        (asa12_mode_v) ? googletag.pubads().setTargeting("asa12_mode", asa12_mode_v) : null;
        (Pg_Type) ? googletag.pubads().setTargeting("Pg_Type", Pg_Type) : null;
        (Pg_Path) ? googletag.pubads().setTargeting("Pg_Path", Pg_Path) : null;
        (asadigi_member_type) ? googletag.pubads().setTargeting("asadigi_member_type", asadigi_member_type) : null;
        // piano用k-v
        (segmentIds) ? googletag.pubads().setTargeting("CxSegments" , segmentIds) : null;

        googletag.pubads().disableInitialLoad();
        googletag.pubads().enableSingleRequest();
        googletag.pubads().collapseEmptyDivs();
        googletag.enableServices();

        if (nonHeaderBiddingSlots.length > 0) {
            googletag.pubads().refresh(nonHeaderBiddingSlots);
        }
    });

    // A9 adSlots 定義
    apstagSlots = [{
        slotID: 'div-gpt-ad-1492771520777-0',
        slotName: '/57465213/www.asahi.com/sp/top/1st_bnr',
        sizes: [[1, 1], [320, 50], [320, 100], [320, 180]]
    }];
    if (window.matchMedia('screen and (max-width: 335px)').matches){
        apstagSlots.push({
            slotID: 'div-gpt-ad-1492771520777-3',
            slotName: '/57465213/www.asahi.com/sp/top/footer_rect',
            sizes: [[300, 250]]
        });
    } else {
        apstagSlots.push({
            slotID: 'div-gpt-ad-1492771520777-3',
            slotName: '/57465213/www.asahi.com/sp/top/footer_rect',
            sizes: [[300, 250], [336, 280]]
        });
    }
}

googletag.cmd.push( function () {
    fetchHeaderBids(apstagSlots, headerBiddingSlots);
} );
